import React from 'react';

import { SantaClausHeader } from '@widgets/santa-claus/landing/santa-claus-header';
import { SantaClausWrapper } from '@widgets/santa-claus/landing/santa-claus-wrapper';
import { SantaClausDetails } from '@widgets/santa-claus/landing/santa-claus-details';
import { SantaClausPartners } from '@widgets/santa-claus/landing/santa-claus-partners';
import { SantaClausFooter } from '@widgets/santa-claus/landing/santa-claus-footer';

const detailsItems = [
  {
    text: 'Напиши листа Миколаю, вказавши подарунок, який ти хочеш отримати.',
  },
  {
    text: 'Прикріпи до листа новорічний малюнок. З цим тобі допоможуть батьки.',
  },
  {
    text: 'Очікуй на диво',
  },
];

const SantaClausCustomerPage = () => {
  return (
    <div>
      <SantaClausHeader
        header={
          <>
            <p>єМрія?</p>
            <p>єМиколай!</p>
          </>
        }
        background="bg-santa-claus-header-customer"
      >
        <p className="mb-7">Привіт!</p>

        <p>
          Якщо ти маленький хлопчик або дівчинка і давно мрієш отримати подарунок, ми допоможемо звернути увагу Миколая
          на твою мрію!
        </p>
      </SantaClausHeader>
      <SantaClausWrapper>
        {/* <SantaClausPartners /> */}

        <SantaClausDetails
          mainContent={<p>Для цього тобі потрібно виконати лише три кроки. Не хвилюйся, це просто.</p>}
          secondaryContent={
            <div>
              <p className="font-bold mb-6">Зверни увагу:</p>

              <p className="mb-5">У Миколая дууже багато маленьких мрійників, тому він не може допомогти кожному.</p>

              <p className="mb-5">
                Але не хвилюйся. Навіть якщо Миколай особисто не зможе втілити твою мрію, його помічники приготують тобі
                приємний сюрприз.
              </p>

              <p className="font-regular text-left block p-1 text-gray text-xxxs md:text-sm">
                * Зверніть увагу! Розміщення листа до єМиколая на платформі єДопомога не гарантує отримання подарунка.
                Благодійник на свій власний розсуд обирає лист та приймає рішення чи відгукнутись на цю заявку, чи ні.
              </p>
            </div>
          }
          items={detailsItems}
        />

        {/* <SantaClausFooter
          text="Заявки приймаються з 1 грудня по 14 грудня 2022 року (включно)"
          buttonLink="/santa-claus/letter"
          buttonText="Написати єМиколаю"
        /> */}
      </SantaClausWrapper>
    </div>
  );
};

export default SantaClausCustomerPage;
